import React from "react"
import { StoryGroup } from "components"

const eBooks = [
  {
    title: "JS Meta Programlama",
    url: "https://learnreactui.dev/contents/javascript-meta-programlama",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fd257969f-7741-4875-a338-ff800784bdd9%2Fcovers%2FCover2.png",
  },
]

export const LinkMap = {
  "Meta Programming": "276a1fd6fcee",
  "eval, new, Proxy": "276a1fd6fcee",
  "Static Code": "91907b1031f8",
  "Dynamic Code": "91907b1031f8",
  "Code Generation": "91907b1031f8",
  Eval: "91907b1031f8",
  "New Function": "91907b1031f8",
  "VM Create Context": "91907b1031f8",
  Introspection: "bc84c0d1a2fd",
  "Self-modification": "bc84c0d1a2fd",
  Intercession: "bc84c0d1a2fd",
  Proxy: "ff2dc05b8f2",
  "Proxy Revoke": "ff2dc05b8f2",
  Reflect: "ff2dc05b8f2",
  TracePropertyAccess: "f849c8ab353e",
  DataBinding: "f849c8ab353e",
}

const stories = [
  {
    title: "JS ile Meta Programlama Eğitim İçeriği",
    postId: "276a1fd6fcee",
  },
  {
    title: "JS ile MetaProgramming (Dinamik Kod)",
    postId: "91907b1031f8",
  },
  {
    title: "JS ile MetaProgramming-0 (Dinamik Obj)",
    postId: "7890789c2c12",
  },
  {
    title: "JS ile MetaProgramming-1",
    postId: "bc84c0d1a2fd",
  },
  {
    title: "JS ile MetaProgramming-2",
    postId: "ff2dc05b8f2",
  },
  {
    title: "JS ile MetaProgramming-3",
    postId: "f849c8ab353e",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "JS Meta Programming",
  path: "javascript-metaprogramming",
}

const JSMetaProgrammingPage = () => {
  return (
    <StoryGroup
      eBooks={eBooks}
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default JSMetaProgrammingPage
